* {
  box-sizing: border-box;
}

:before, :after {
  box-sizing: inherit;
}

body {
  color: #fff;
  background: linear-gradient(to right, #159957, #155799);
  font-family: ubuntu mono;
  font-size: 1rem;
  line-height: 1.2;
}

h1, h2 {
  text-align: center;
  margin: 0 0 1rem;
  font-family: Righteous;
}

#root {
  overflow: hidden;
}

.app {
  max-width: 50rem;
  margin: auto;
  padding: 1rem;
}

form, .form-group {
  flex-flow: wrap;
  align-items: flex-start;
  margin: 0 -.5rem;
  display: flex;
}

form {
  padding-bottom: 1rem;
}

form button {
  color: #fff;
  cursor: pointer;
  background: #333;
  border: 0;
  border-radius: .5rem;
  padding: .5rem 2rem;
  font-size: 2rem;
  line-height: 1.2;
  transition: top .1s ease-in-out;
  position: relative;
  top: 0;
  box-shadow: 0 0 .5rem #00000080;
}

form button:hover {
  background-color: #3a3a3a;
}

form button:active {
  top: .125rem;
}

.form-row {
  color: #333;
  flex-flow: wrap;
  flex: 0 0 33.3333%;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 33.3333%;
  padding: 0 .5rem 1rem;
  display: flex;
}

.form-row small {
  color: #fff;
  margin: .5rem 0;
}

.form-row label {
  color: #fff;
  flex-flow: row;
  flex: 0 0 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: .5rem;
  font-size: .875rem;
  line-height: 1.25;
  display: flex;
}

.form-row label small {
  margin: 0 0 0 auto;
  display: block;
}

.form-row input, .form-row select {
  color: inherit;
  height: 2.5rem;
  box-shadow: none;
  background: #fff;
  border: 1px solid;
  border-radius: .5rem;
  padding: .4375rem .5rem;
  font-size: .75rem;
  line-height: 2;
  display: block;
}

.form-row input.has--error, .form-row select.has--error {
  background-color: #f003;
}

.form-row input[disabled], .form-row select[disabled] {
  background-color: #ccc;
}

.form-row input:only-of-type, .form-row select:only-of-type {
  width: 100%;
}

.form-row input:not(:only-of-type):first-of-type, .form-row select:not(:only-of-type):first-of-type {
  width: 70%;
}

.form-row input:not(:only-of-type):last-of-type, .form-row select:not(:only-of-type):last-of-type {
  width: calc(30% - 1rem);
}

.form-row input[type="file"], .form-row input[type="color"], .form-row select[type="file"], .form-row select[type="color"] {
  padding: 0;
}

.form-row input[type="color"], .form-row select[type="color"] {
  cursor: pointer;
  border-radius: .625rem;
}

.form-row input[type="color"]::-webkit-color-swatch {
  border: none;
  border-radius: .5rem;
}

.form-row select[type="color"]::-webkit-color-swatch {
  border: none;
  border-radius: .5rem;
}

.form-row input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}

.form-row select[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}

.form-row input[type="color"]::-moz-color-swatch {
  border: none;
  border-radius: .5rem;
}

.form-row input[type="color"]::-moz-focus-inner {
  border: none;
  border-radius: .5rem;
}

.form-row select[type="color"]::-moz-color-swatch {
  border: none;
  border-radius: .5rem;
}

.form-row select[type="color"]::-moz-focus-inner {
  border: none;
  border-radius: .5rem;
}

.form-row input[type="color"]::-moz-focus-inner {
  padding: 0;
}

.form-row select[type="color"]::-moz-focus-inner {
  padding: 0;
}

.form-actions {
  text-align: center;
  flex: 0 0 100%;
}

.form-group .form-row {
  flex: 0 0 50%;
  max-width: 50%;
}

.form-group .form-row:only-child {
  margin: auto;
}

.preview {
  border: 1px solid;
  margin-bottom: 1rem;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.preview * {
  flex: 0 0 100%;
  width: 100%;
}

.preview svg {
  z-index: 3;
  stroke: currentColor;
  width: 100px;
  height: 100px;
  margin: auto;
  display: block;
  position: absolute;
  inset: 0;
}

.preview:empty {
  border: 1px solid;
  background: none !important;
}

.preview:empty:before {
  content: "EMPTY";
}

.preview__content {
  text-align: center;
  z-index: 2;
  flex-flow: wrap;
  place-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 0;
}

.preview__background {
  z-index: 1;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: relative;
}

social-links {
  padding: 1rem 0;
  display: block;
}

@media (width <= 768px) {
  .form-row, .form-group .form-row {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
/*# sourceMappingURL=path0.d092a8c9.css.map */
